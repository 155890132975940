import React, { Component } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import List from '../components/board/product/List';
import View from '../components/board/product/View';
import PageHeader from '../components/PageHeader';
import {MDBContainer} from 'mdbreact';
import * as event from'../service/event';
import { Helmet } from "react-helmet";
// css, scss
import "../css/mask.scss";

class Mask extends Component {
  constructor(props) {
    super(props);
    this.scrollEvent.bind(this);
  }
  componentDidMount() {
    event.pagenations("Print", "3")
    event.pageIn("#MaskPage")
    window.addEventListener('scroll',this.scrollEvent, false)
  }
  componentWillUnmount() {
    event.pageOut("#MaskPage")
    window.removeEventListener('scroll',this.scrollEvent, false)
  }
  scrollEvent = () => {
    event.tabNavs("#MaskNavs");
  }
  render() {
    return ( 
      <main id="MaskPage">
        <Helmet>
          <title>PRINTIS 마스크장비</title>
        </Helmet>
        <PageHeader 
          page="mask"
          pagebg="http://printis.cdn3.cafe24.com/main/MainBanner.jpg"
          pageText="마스크 장비"
          title="PRINTIS 마스크 장비"
        />
        <section id="maskConts">
          <div id="MaskNavs" className="tabNavWrapper">
            <MDBContainer>
              <ul id="maskNavList" className="clear subNav">
                <li>
                  <NavLink activeClassName="active" to={"/Mask/Mask/mask"}>마스크장비</NavLink>
                </li>
              </ul>
            </MDBContainer>
          </div>
          <div id="maskConts" className="boardListConts">
            <MDBContainer>
              <Switch>
                <Route 
                  exact 
                  path={`/:page?/:board?/:category`} 
                  component={List}
                />
                <Route  
                  path={`/:page?/:board?/:category?/:id`} 
                  component={View}
                />
              </Switch>
            </MDBContainer>
          </div>
        </section>
      </main>
    )
  }
}
export default Mask;