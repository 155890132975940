import React, { Component } from 'react';

// 부모컴포넌트
class Test extends Component {
  render() {
    return(
      <div>
        <Test2 
          test="false" 
        />
      </div>
    )
  }
}

// 자식컴포넌트
class Test2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "childTest" : ""
    }
  }
  shouldComponentUpdate() {
    const propsTest = this.props.test;
    if (propsTest === "true") {
      console.log("업데이트 실패")
      return false;
    } else {
      console.log("업데이트 성공")
      return true;
    }
  }
  componentDidMount() {
    console.log("component did mount");
  }
  test() {
    this.setState({
      "childTest" : this.props.test 
    })
  }
  render() {
    console.log("render")
    return (
      <div>
        {this.state.childTest}
        <button onClick={(e) => this.test()}>테스트버튼</button>
      </div>
    )
  }
}

export default Test;

