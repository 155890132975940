import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    return (
      <div>
        <h3>잘못된 접근입니다.</h3>
      </div>
    )
  }
}

export default NotFound;